<template>
  <a-drawer width='35%' :label-col='4' :wrapper-col='14' :visible='open' @close='onClose'>
    <a-divider orientation='left'>
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref='form' :model='form'>

      <a-form-model-item label='一级分类' prop='pid' :required='true'>
        <a-select v-model='form.pid'
          showSearch
          placeholder="请选择一级分类"
          style="width: 400px" @change="onChangePid">
          <a-select-option v-for="category in pCategoryList" :value="category.id">{{ category.categoryName }}
          </a-select-option>
        </a-select>
        <a style="margin-left: 10px;" @click="addPCategory">新建一级分类</a>
      </a-form-model-item>
      <a-form-model-item  label='二级分类' prop='id' :required='true'>
        <a-select v-model='form.id'
                  showSearch allowClear
                  placeholder="请选择二级分类"
                  style="width: 400px" @change="onChange" :filter-option="filterOption">
          <a-select-option v-for="category in categoryList" :value="category.id" :label = "category">{{ category.categoryName }}
          </a-select-option>
        </a-select>
        <a style="margin-left: 10px;" @click="addCategory">新建二级分类</a>
      </a-form-model-item>
      <a-form-model-item label='曲调' prop='tuneId' :required='true'>
        <a-select v-model='form.tuneId'
                  showSearch allowClear
                  placeholder="请选择曲调"
                  style="width: 400px" >
          <a-select-option v-for="tune in tuneList" :value="tune.id">{{ tune.name }}
          </a-select-option>
        </a-select>

        <a style="margin-left: 10px;" @click="addTune">新建曲调</a>
      </a-form-model-item>
<!--      <a-form-model-item label='分类图片' prop='categoryPic'>-->
<!--        <a-input v-model='form.categoryPic' placeholder='请输入分类图片' />-->
<!--      </a-form-model-item>-->
      <div class='bottom-control'>
        <a-space>
          <a-button type='primary' :loading='submitLoading' @click='submitForm'>
            保存
          </a-button>
          <a-button type='dashed' @click='cancel'>
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <category-model ref="categoryModel" @close="auditClose" ></category-model>
    <div style="width: 500px">
      <a-modal title="添加曲调" :visible="visible" @ok="addMusicTune" :confirmLoading="confirmLoading" @cancel="handleCancel"
               cancelText="取消">
        <a-spin :spinning="confirmLoading">
          <a-form :form="tuneForm" :rules='tuneRules'>
            <a-form-item label="曲调名称" :required='true'>
              <a-input v-model='tuneForm.name' placeholder='请输入曲调名称(20字内)' :maxLength='20' />
            </a-form-item>
            <a-form-item label="排序" :required='true'>
              <a-input-number v-model='tuneForm.sort' placeholder='请输入排序' :min='1' :maxLength='10' :max='9999' />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </div>
  </a-drawer>

</template>

<script>
import {listGraphCategory } from '@/api/dynamic/graphCategory'
import Editor from '@/components/Editor'
import { Log } from '@/components/crud/Log'
import UploadFileToOSS from '@/utils/upload-file-to-oss'
import { addGraphTune, listGraphTune } from '@/api/dynamic/graphTune'
import { addMusicQuote } from '@/api/dynamic/musicLibrary'

import CategoryModel from './CategoryModel.vue'
export default {
  name: 'CreateQuote',
  props: {}
  ,
  components: {
    Editor,
    CategoryModel
  }
  ,
  data() {
    return {
      submitLoading: false,
      formTitle: '添加引用',
      loading:false,
      visible:false,
      confirmLoading:false,
      pCategoryList:[],//一级分类
      categoryList: [],//二级分类
      tuneList:[],//曲调
      // 表单参数
      form: {
        id: null,

        categoryName: null,

        pid: null,
        tuneId:null,
        level: null,

        content: null,

        sort: null,

        icon: null,

        categoryPic: null,

      },
      tuneForm: {
        id:null,
        name: null,

        sort: null,

      },
      tuneRules: {
        name: [
          {
            required: true, message: '曲调名称不能为空', trigger: 'blur'
          }
        ],

      },
      // 1增加,2修改
      formType: 1,
      open: false,

      data:[],//歌曲id
    }
  }
  ,
  filters: {}
  ,
  created() {
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  mounted() {
  }
  ,
  methods: {
    onChange(value) {
      this.$emit('change', value);
      this.$emit('input', value);
    },
    // 查询条件
    filterOption(input, optionComponent) {
      // debugger
      const option = optionComponent.componentOptions.propsData.label;

      return (option.categoryName && option.categoryName.includes(input)) ||
        (option.id && option.id.includes(input)) ||
        (option.categoryName && option.categoryName.includes(input))
    },
    //添加一级分类
    addPCategory(){
      this.$refs.categoryModel.audit(1);
    },
    //添加二级分类
    addCategory(){
      this.$refs.categoryModel.audit(2);
    },
    //添加曲调
    addTune(){
      this.visible = true
      this.tuneForm = {
        id:null,
        name: null,
        sort:null
      }
    },
    onChangePid(value) {
      this.form.pid = value;
      this.form.id = null;
      this.categoryList = [];
      const that = this;
      listGraphCategory({pid:value}).then(res => {
        if (res.success) {
          that.categoryList = [];
          that.categoryList = res.data;
          console.log(that.categoryList)
        } else {
          console.log(res.message);
        }
      })
    },
    //初始化分类数据
    getCategoryValue(pid) {
      const that = this;
      listGraphCategory({pid:pid}).then(res => {
        if (res.success) {
          that.pCategoryList = [];
          that.pCategoryList = res.data;
          console.log(that.pCategoryList)
        } else {
          console.log(res.message);
        }
      })
    },
    //初始化曲调数据
    getTuneValue() {
      const that = this;
      listGraphTune().then(res => {
        if (res.success) {
          that.tuneList = [];
          that.tuneList = res.data;
          console.log(that.tuneList)
        } else {
          console.log(res.message);
        }
      })
    },
    onClose() {
      this.open = false
    }
    ,
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    }
    ,
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        categoryName: null,
        tuneId: null,
        pid: null,
        level: null,
        content: null,
        sort: null,
        icon: null,
        categoryPic: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    }
    ,
    /** 新增按钮操作 */
    handleAdd(data) {
      this.reset()
      this.formType = 1
      this.open = true;
      this.data = data;
      this.getCategoryValue(0);
      this.getTuneValue();
    },
    /** 提交按钮 */
    submitForm: function() {
      if (this.form.pid == '' || this.form.pid == null) {
        this.$warning({
          title: '提示',
          content: '请选择一级分类'
        })
        return
      }
      if (this.form.id == '' || this.form.id == null) {
        this.$warning({
          title: '提示',
          content: '请选择二级分类'
        })
        return
      }
      if (this.form.tuneId == '' || this.form.tuneId == null) {
        this.$warning({
          title: '提示',
          content: '请选择曲调'
        })
        return
      }
      this.submitLoading = true
      let quoteData = []
      Log.info("data===",this.data)
      this.data.map(id=>{
        quoteData.push({'categoryId': this.form.id,'categoryPId':this.form.pid,'tuneId':this.form.tuneId,'musicLibraryId':id});
      });
      Log.info("quoteData===",quoteData)
      addMusicQuote(quoteData).then(response => {
        this.$message.success(
          '新增成功',
          3
        )
        this.open = false
        this.$emit('ok')
      }).finally(() => {
        this.submitLoading = false
      })
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(field,fileInfo) {
      // 图片类型字段, 进行文件类型判断
      if (!fileInfo.type.includes('image')) {
        this.$message.warning('请上传图片');
        return false;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(field,fileInfo) {
      Log.info('uploadfileInfo', fileInfo);
      debugger
      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          Log.info('upload', res);
          this.loading = false;
          // this.form.imgUrl = res[0].url;
          this.form[field] = res[0].url;
        },
      });
    },
    //创建分类弹窗关闭
    auditClose() {
      this.getCategoryValue(0);
      this.onChangePid(this.form.pid);
    },
    addMusicTune(){
      if (this.tuneForm.name == '' || this.tuneForm.name == null) {
        this.$warning({
          title: '提示',
          content: '请输入曲调名称'
        })
        return
      }
      if (this.tuneForm.sort == '' || this.tuneForm.sort == null) {
        this.$warning({
          title: '提示',
          content: '请输入排序'
        })
        return
      }
      this.confirmLoading = true
      addGraphTune(this.tuneForm).then(response => {
        this.$message.success(
          '新增成功',
          3
        )
        this.confirmLoading = false;
        this.visible = false;
        this.getTuneValue();
      }).finally(() => {
        this.confirmLoading = false
      })
    },
    handleCancel(){
      this.visible = false;
      this.getTuneValue();
    },
  }
}
</script>
